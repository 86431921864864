import React from 'react';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';
// import Grev55Tab from './Tabs/Grev55Tab';
import { PropertiesWrapper } from 'layouts';

const PropertiesView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main bgcolor={theme.palette.background.default}>
      <PropertiesWrapper>{/* <Grev55Tab /> */}</PropertiesWrapper>
    </Main>
  );
};

export default PropertiesView;
